import React from "react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import tw from "tailwind.macro";
import Media from "react-media";

const Container = styled.div`
  ${tw`relative flex-1 w-full md:w-2/4 rounded overflow-hidden`}
`;

const Player = styled.video`
  ${tw`absolute`}
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
`;

function Video({ url }) {
  return (
    <Media query={{ minWidth: 640 }}>
      <Container>
        <Player loop autoPlay muted>
          <source src={url} type="video/mp4" />
          <source src={url} type="video/ogg" />
        </Player>
      </Container>
    </Media>
  );
}

export default Video;
