import React from "react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import tw from "tailwind.macro";
import Slider from "react-slick";
import TextTruncate from "react-text-truncate";
import RenderRating from "../../Testimonial/RenderRating";

const Item = styled.div`
  ${tw`sm:p-4`}
`;

const Container = styled.div`
  ${tw`sm:p-6`}
`;

const Rating = styled.div`
  ${tw`flex flex-row`}
`;

const Quote = styled.div`
  ${tw`py-4 px-0`}
`;

const Person = styled.cite`
  ${tw`block not-italic mb-3`}
`;

const Name = styled.div`
  ${tw`font-medium`}
`;

function TestimonialSlider({ testimonials }) {
  let settings = {
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 7000,
    infinite: true,
    accessibility: false,
    swipe: false,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          infinite: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          infinite: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <React.Fragment>
      {testimonials.map((item, index) => (
        <Slider key={index} {...settings}>
          {item.recommendations.edges.map(({ node }, innerIndex) => (
            <Item key={innerIndex}>
              <Container>
                <Rating>
                  <RenderRating
                    score={node.recommendationCreation.customerRating}
                  />
                </Rating>
                <Quote>
                  <TextTruncate
                    line={3}
                    truncateText="..."
                    text={node.recommendationCreation.customerReview}
                  />
                </Quote>
                <Person>
                  <Name>{node.recommendationCreation.customerName}, </Name>
                  <div>{node.recommendationCreation.customerCompany}</div>
                </Person>
              </Container>
            </Item>
          ))}
        </Slider>
      ))}
    </React.Fragment>
  );
}

export default TestimonialSlider;

export const query = graphql`
  fragment USPTestimonialSliderFragment on WP_Page_Pagebuilder_Layouts_SectionBlock_Sections_UspTestimonialSlider {
    testimonials {
      recommendations(first: 100) {
        edges {
          node {
            recommendationCreation {
              customerRating
              customerName
              customerReview
              customerCompany
            }
          }
        }
      }
    }
  }
`;
