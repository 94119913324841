import React from "react";
import { graphql } from "gatsby";
import YouTubePlayer from "../Player/Youtube";

function VideoEmbed({ video }) {
  return (
    <YouTubePlayer url={video} />
  );
}

export default VideoEmbed;

export const query = graphql`
  fragment VideoEmbedFragment on WP_Page_Pagebuilder_Layouts_SectionBlock_Sections_VideoEmbed {
    video
  }
`;
