import React from "react";
import styled from "@emotion/styled";
import tw from "tailwind.macro";
import CheckmarksIcon from "../../../images/checkmark.svg"

const ListItem = styled.li`
  ${tw`list-none sm:inline-block sm:mr-6`}

  &:last-of-type {
    ${tw`mr-0`}
  }
`

const Icon = styled.span`
  ${tw`inline-block mr-2`}
  background: url("${CheckmarksIcon}") no-repeat;
  height: 12px;
  width: 12px;
`

function Checkmark({ text }) {
  return (
    <ListItem>
      <Icon />
      <span>{text}</span>
    </ListItem>
  );
}

export default Checkmark;
