import React from "react";
import { graphql } from "gatsby";
import HtmlWrapper from "../components/HtmlWrapper";
import SectionBlock from "../components/Blocks/Section";

class Page extends React.Component {
  render() {
    const { data } = this.props;
    const pageData = data.wp.page;
    const layouts = pageData.pageBuilder.layouts;

    return (
      <HtmlWrapper seo={pageData}>
        {layouts.map((section, index) => {
          return (
            <SectionBlock key={section.__typename + "-" + index} {...section} />
          );
        })}
      </HtmlWrapper>
    );
  }
}

export default Page;

export const query = graphql`
  query PageQuery($id: ID!) {
    wp {
      page(id: $id) {
        id
        title
        ...YoastPage
        pageBuilder {
          layouts {
            __typename

            ...SectionBlockFragment
          }
        }
      }
    }
  }
`;
