import React from "react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import tw from "tailwind.macro";
import Media from "react-media";
import { GatsbyImage } from "gatsby-plugin-image";
import Button from "../../Button";
import ReviewCategory from "../../Testimonial/TestimonialCategory";

const Grid = styled.div`
  ${tw`w-full sm:w-3/6`}
`;

const Content = styled.article`
  ${tw`w-full`}
  ${(props) => (props.layout ? tw`pl-0 sm:pl-24` : tw`pr-0 sm:pr-24`)}
`;

const H2 = styled.h2`
  ${tw`text-4xl sm:text-5xl my-5 font-semibold`}
`;

const Subheading = styled.p`
  ${tw`text-2xl`}
`;

const Image = styled((props) => <GatsbyImage {...props} />)`
  ${tw`w-full`}
`;

const StyledButton = styled((props) => <Button {...props} />)`
  ${tw`mt-5`}
`;

function CategoryDescription({ data, reversed }) {
  return (
    <Grid>
      <Content layout={reversed}>
        <H2>{data.heading}</H2>
        <Subheading>{data.subheading}</Subheading>
        <Media query={{ maxWidth: 640 }}>
          <Image
            image={data.image.imageFile.childImageSharp.gatsbyImageData}
            alt={data.image.alt}
            objectPosition="50% 50%"
          />
        </Media>
        <StyledButton to={data.buttonLink.uri}>{data.buttonText}</StyledButton>
        <ReviewCategory
          quote={data.testimonial}
          source={data.testimonialSource}
          company={data.testimonialCompany}
        />
      </Content>
    </Grid>
  );
}

export default CategoryDescription;

export const query = graphql`fragment CategoryDescriptionFragment on WP_Page_Pagebuilder_Layouts_SectionBlock_Sections_SplitCategoryDesc_categories {
  heading
  subheading
  buttonLink {
    ... on WP_Page {
      uri
    }
  }
  image {
    sourceUrl
    altText
    imageFile {
      childImageSharp {
        gatsbyImageData(
          width: 600
          height: 400
          quality: 100
          transformOptions: {cropFocus: CENTER, grayscale: true}
          layout: CONSTRAINED
        )
      }
    }
  }
  buttonText
  testimonial
  testimonialSource
  testimonialCompany
}
`;
