import React from "react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import tw from "tailwind.macro";

const Content = styled.article`
  ${tw`flex flex-wrap`}

  a {
    ${tw`text-primary`}
  }
`;

const Title = styled.h2`
  ${tw`text-3xl font-semibold mb-8`}
  ${props => (props.centered === true ? tw`text-center` : tw`text-left`)}
  ${props =>
    props.setSpacing === "2"
      ? tw`sm:px-8`
      : props.setSpacing === "3"
      ? tw`sm:px-8`
      : props.setWidth === "4"
      ? tw`sm:px-8`
      : tw``}
`;

const Label = styled.div`
  ${tw`uppercase tracking-wider text-primary`}
`;

const Column = styled.div`
  ${tw`mb-8 sm:mb-16 sm:px-8`}
  ${props =>
    props.setWidth === "1"
      ? tw`w-full sm:px-0`
      : props.setWidth === "2"
      ? tw`w-full md:w-1/2`
      : props.setWidth === "3"
      ? tw`w-full md:w-1/2 lg:w-1/3`
      : props.setWidth === "4"
      ? tw`w-full md:w-1/2 lg:w-1/4`
      : tw``}
`;

function ColumnBuilderTextBlock({ title, centerTitle, columnWidth, columns }) {
  return (
    <React.Fragment>
      <Title centered={centerTitle} setSpacing={columnWidth}>
        {title}
      </Title>
      <Content>
        {columns.map((column, index) => (
          <Column setWidth={columnWidth} key={index}>
            <Label>{column.label}</Label>
            <div dangerouslySetInnerHTML={{ __html: column.text }} />
          </Column>
        ))}
      </Content>
    </React.Fragment>
  );
}

export default ColumnBuilderTextBlock;

export const query = graphql`
  fragment ColumnBuilderTextBlockFragment on WP_Page_Pagebuilder_Layouts_SectionBlock_Sections_ColumnBuilderTextBlock {
    title
    centerTitle
    columnWidth
    columns {
      label
      text
    }
  }
`;
