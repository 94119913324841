import React from "react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import tw from "tailwind.macro";

const Content = styled.article`
  ${tw`m-auto w-full md:w-10/12 lg:w-2/3`}

  a {
    ${tw`text-primary`}
  }
`

const Title = styled.h2`
  ${tw`text-5xl font-semibold`}
`

const Wrapper = styled.div`
  ${tw`mb-16`}

  &:last-of-type {
    ${tw`mb-0`}
  }
`

const Subheading = styled.h3`
  ${tw`text-3xl font-semibold`}
`

function DescBlockLarge({ descTitle, descContent }) {
  return (
    <Content>
      <Title>{descTitle}</Title>
      {descContent.map((content, index) => (
        <Wrapper key={index}>
          <Subheading>{content.subheading}</Subheading>
          <div
            dangerouslySetInnerHTML={{
              __html: content.description
            }}
          />
        </Wrapper>
      ))}
    </Content>
  );
}

export default DescBlockLarge;

export const query = graphql`
  fragment DescBlockLargeFragment on WP_Page_Pagebuilder_Layouts_SectionBlock_Sections_DescBlockLarge {
    descTitle
    descContent {
      subheading
      description
    }
  }
`;
