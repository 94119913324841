import React from "react";
import styled from "@emotion/styled";
import tw from "tailwind.macro";

const Content = styled.div`
  ${tw`mt-16 lg:w-10/12`}
  border-top: 1px dotted #fff;
`;

const Quote = styled.div`
  ${tw`py-8`}
`;

const Person = styled.cite`
  ${tw`block mb-8 not-italic`}
`;

const Name = styled.span`
  ${tw`font-semibold`}
`;

const ReviewCategory = props => {
  return (
    <Content>
      <Quote>“{props.quote}“</Quote>
      <Person>
        <Name>{props.source}, </Name>
        <span>{props.company}</span>
      </Person>
    </Content>
  );
};

export default ReviewCategory;
