import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "@emotion/styled";
import tw from "tailwind.macro";
import Bar from "./Bar";
import DisplayPrice from "./DisplayPrice";
import LinkExternal from "../../Links/LinkExternal";
import Button from "../../Button";
import { createLocalLink } from "../../../utils/index";
const businessData = require("../../Contact/business");

const Padding = styled.div`
  ${tw`p-5`}
`;

const CategoryImg = styled((props) => <GatsbyImage {...props} />)`
  border-top-left-radius: ${(props) => (props.isHighlighted ? "" : ".25rem")};
  border-top-right-radius: ${(props) => (props.isHighlighted ? "" : ".25rem")};
`;

const PhoneWrapper = styled.div`
  ${tw`mt-10 mb-2 text-center`}
`;

function TopInfo({ data, highlightCard }) {
  const { telephone } = businessData;

  return (
    <React.Fragment>
      <Bar isHighlighted={highlightCard} />
      <CategoryImg
        isHighlighted={highlightCard}
        image={data.categoryImage.imageFile.childImageSharp.gatsbyImageData}
        alt={data.categoryImage.altText}
      />
      <Padding>
        <DisplayPrice category={data.categoryName} price={data.price} />
        <Button
          primary={highlightCard}
          full="true"
          to={createLocalLink(data.buttonLink.link)}
        >
          {data.buttonText}
        </Button>
        <PhoneWrapper>
          <LinkExternal
            href={`tel:${telephone}`}
            text="Ring for professionel vejledning"
          />
        </PhoneWrapper>
      </Padding>
    </React.Fragment>
  );
}

export default TopInfo;

export const TopInfoQuery = graphql`fragment TopInfoFragment on WP_Page_Pagebuilder_Layouts_SectionBlock_Sections_PricingTable_pricingCategories {
  categoryImage {
    altText
    sourceUrl
    imageFile {
      childImageSharp {
        gatsbyImageData(
          width: 400
          height: 220
          quality: 100
          transformOptions: {cropFocus: CENTER, grayscale: true}
          layout: CONSTRAINED
        )
      }
    }
  }
  categoryName
  price
  buttonLink {
    __typename
    ... on WP_Page {
      link
    }
  }
  buttonText
}
`;
