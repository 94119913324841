import React from "react";
import { graphql, Link } from "gatsby";
import styled from "@emotion/styled";
import tw from "tailwind.macro";
import { GatsbyImage } from "gatsby-plugin-image";
import { createLocalLink } from "../../utils/index";
import { replaceEscapedLetters } from "../../utils/handleEncoding";

function DisplayArtistCategory({ artistCategory }) {
  const filteredCategoryArtists = []

  artistCategory[0].artister.edges.map(({ node }) => {
    node.artistCategories.nodes.map((category) => {
      if (artistCategory[0].slug === category.slug) {
        filteredCategoryArtists.push(node);
      }
    })
  });

  return (
    <FlexWrapper>
      {filteredCategoryArtists.map((artist, index) => (
        <Item key={index}>
          <Link to={createLocalLink(artist.link)}>
            <FeaturedImage
              image={
                artist.featuredImage.imageFile.childImageSharp.gatsbyImageData
              }
              alt={artist.featuredImage.altText}
            />
            <Title>{replaceEscapedLetters(artist.title)}</Title>
          </Link>
        </Item>
      ))}
    </FlexWrapper>
  );
}

export default DisplayArtistCategory;

export const query = graphql`fragment DisplayArtistCategoryFragment on WP_Page_Pagebuilder_Layouts_SectionBlock_Sections_DisplayArtistCategory {
  artistCategory {
    __typename
    slug
    artister(first: 200) {
      edges {
        node {
          title
          link
          artistCategories {
            nodes {
              slug
            }
          }
          featuredImage {
            sourceUrl
            altText
            imageFile {
              childImageSharp {
                gatsbyImageData(
                  width: 300
                  height: 400
                  quality: 100
                  transformOptions: {cropFocus: CENTER, grayscale: true}
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
      }
    }
  }
}
`;

const FlexWrapper = styled.div`
  ${tw`flex flex-wrap -mx-4`}
`;

const Item = styled.div`
  ${tw`w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/4 mb-10 px-4`}
`;

const FeaturedImage = styled((props) => <GatsbyImage {...props} />)`
  ${tw`rounded`}
`;

const Title = styled.h2`
  ${tw`text-2xl font-bold pt-2`}
`;
