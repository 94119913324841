import React from "react";
import { graphql } from "gatsby";
import Slider from "react-slick";

function ClientLogoSlider ({ companyLogos }) {
  var settings = {
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 0,
    infinite: true,
    accessibility: false,
    swipe: false,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    cssEase: "linear",
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <Slider {...settings}>
      {companyLogos.map((logo, index) => (
        <div key={index} style="maxWidth: 200px;">
          <img src={logo.link} alt={logo.altText} />
        </div>
      ))}
    </Slider>
  );
};

export default ClientLogoSlider;

export const query = graphql`
  fragment USPClientLogoSliderFragment on WP_Page_Pagebuilder_Layouts_SectionBlock_Sections_UspClientLogoSlider {
    companyLogos {
      ... on WP_MediaItem {
        link
        altText
      }
    }
  }
`;
