import React from "react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import tw from "tailwind.macro";
import TopInfo from "./TopInfo";
import Container from "../../Container";

const Spacing = styled.div`
  ${tw`mt-8 sm:mt-24`}
`

const FlexWrapper = styled.div`
  ${tw`flex flex-wrap -mx-4`}
`;

const Table = styled.section`
  ${tw`flex w-full md:w-1/2 lg:w-1/3 mb-10 sm:px-4`}
`;

const Border = styled.div`
  ${tw`border border-solid rounded`}
  ${props => (props.highlightCard ? tw`border-primary mt-8 sm:mt-0` : tw`border-black-600`)}
`;

const Divider = styled.hr`
  ${tw`border-t border-solid border-black-600`}
`;

const Description = styled.article`
  ${tw`p-5`}
`;

function PricingTable({ pricingCategories }) {
  return (
    <Spacing>
      <Container>
        <FlexWrapper>
          {pricingCategories.map((category, index) => (
            <Table key={index}>
              <Border highlightCard={category.highlight}>
                <TopInfo data={category} highlightCard={category.highlight} />
                <Divider />
                <Description
                  dangerouslySetInnerHTML={{ __html: category.categoryDesc }}
                />
              </Border>
            </Table>
          ))}
        </FlexWrapper>
      </Container>
    </Spacing>
  );
}

export default PricingTable;

export const PricingTableQuery = graphql`
  fragment PricingTableFragment on WP_Page_Pagebuilder_Layouts_SectionBlock_Sections_PricingTable {
    pricingCategories {
      highlight
      categoryDesc
      ...TopInfoFragment
    }
  }
`;
