import styled from "@emotion/styled";
import tw from "tailwind.macro";
import Arrow from "./Links/LinkArrow";

const ExternalButton = styled(props => (
  <a {...props}>
    {props.children} <Arrow />
  </a>
))`
  ${tw`cursor-pointer inline-block text-center align-middle select-none py-3 px-5 font-bold rounded w-full sm:w-auto`}
  font-family: Graphik, "DJB-Text", sans-serif;
  ${props => (props.full ? tw`sm:w-full` : tw``)}
  ${props =>
    props.primary
      ? tw`fill-current text-black-900 bg-primary border border-solid border-primary`
      : tw`text-primary border border-solid border-primary`};
  ${props =>
    props.usp
      ? tw`fill-current text-black-900 border border-solid border-black-900`
      : ""}
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover > svg {
    transform: translateX(4px);
  }
`;

export default ExternalButton;
