import React from "react";
import styled from "@emotion/styled";
import tw from "tailwind.macro";

const WrapperTopBar = styled.div`
  ${tw`flex items-center justify-center border border-solid border-primary rounded-t`}
  border-bottom: 0;
  height: 35px;
  margin: -35px -1px 0;
`;

const Span = styled.span`
  ${tw`text-primary font-semibold`}
`;

function Bar({ isHighlighted }) {
  if (isHighlighted)
    return (
      <WrapperTopBar>
        <Span>62% af kunderne vælger et Live Act</Span>
      </WrapperTopBar>
    );

  return <div />;
}

export default Bar;
