import React from "react";
import styled from "@emotion/styled";
import tw from "tailwind.macro";

const LabelWrapper = styled.div`
  ${tw`w-full text-right`}
`

const FlexWrapper = styled.article`
  ${tw`flex flex-auto mb-6`}
`;

const H2 = styled.h2`
  ${tw`text-2xl font-semibold m-0`}
`;

const Price = styled.div`
  ${tw`flex flex-auto flex-col items-end justify-center m-0`}
`;

const Label = styled.span`
  ${tw`w-full text-right text-lg text-black-100`}
`;

function DisplayPrice({ category, price }) {
  return (
    <React.Fragment>
      <LabelWrapper>
        <Label>fra DKK</Label>
      </LabelWrapper>
      <FlexWrapper>
        <H2>{category}</H2>
        <Price>
          <H2>{price}</H2>
        </Price>
      </FlexWrapper>
    </React.Fragment>
  );
}

export default DisplayPrice;
