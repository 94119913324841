import React from "react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import tw from "tailwind.macro";
import Tabs from "../Tabs/ReviewTabs";
import RenderRating from "../Testimonial/RenderRating";

const Container = styled.div`
  ${tw`flex flex-wrap mt-8`}
`;

const Quote = styled.div`
  ${tw`flex flex-col w-full sm:w-1/2 pb-6 sm:py-12 sm:px-6`}
`;

const Message = styled.article`
  ${tw`py-8 px-4 sm:py-16 sm:px-12 sm:p-16 bg-black-600 rounded`}
`;

const Rating = styled.div`
  ${tw`flex align-middle mb-8`}
`;

const Person = styled.cite`
  ${tw`block not-italic font-semibold mt-4`}
`;

function DisplayTestimonials({ testimonials }) {
  let companyCustomers = [];
  let privateCustomers = [];

  testimonials.map(({ recommendations }) => {
    recommendations.edges.map(({ node }) => {
      if (node.recommendationCreation.customerCompany !== null) {
        companyCustomers.push(node);
      } else {
        privateCustomers.push(node);
      }
    });
  });

  function CustomerQuote({ filter }) {
    return (
      <Container>
        {filter.map(({ recommendationCreation }, index) => (
          <Quote key={index}>
            <Message>
              <Rating>
                <RenderRating score={recommendationCreation.customerRating} />
              </Rating>
              {recommendationCreation.customerReview}
              <Person>{recommendationCreation.customerName}</Person>
              {recommendationCreation.customerCompany && (
                <div>{recommendationCreation.customerCompany}</div>
              )}
            </Message>
          </Quote>
        ))}
      </Container>
    );
  }

  return (
    <Tabs
      companyLabel="Firmakunder"
      privateLabel="Privatkunder"
      company={<CustomerQuote filter={companyCustomers} />}
      private={<CustomerQuote filter={privateCustomers} />}
    />
  );
}

export default DisplayTestimonials;

export const query = graphql`
  fragment DisplayTestimonialsFragment on WP_Page_Pagebuilder_Layouts_SectionBlock_Sections_DisplayTestimonials {
    testimonials {
      __typename

      ... on WP_Review {
        recommendations(first: 1000) {
          edges {
            node {
              recommendationCreation {
                customerRating
                customerName
                customerReview
                customerCompany
              }
            }
          }
        }
      }
    }
  }
`;
