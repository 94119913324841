import React from "react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import tw from "tailwind.macro";

const H1 = styled.h1`
  ${tw`text-4xl sm:text-7xl font-semibold text-left sm:text-center mb-0`}
`;

const H4Paragraph = styled.p`
  ${tw`text-2xl w-full sm:w-7/12 text-left sm:text-center mx-auto`}
`;

function Headline({ headline, subheading }) {
  return (
    <React.Fragment>
      <H1>{headline}</H1>
      <H4Paragraph>{subheading}</H4Paragraph>
    </React.Fragment>
  );
}

export default Headline;

export const fragment = graphql`
  fragment HeroHeadlineFragment on WP_Page_Pagebuilder_Layouts_SectionBlock_Sections_HeroHeadline {
    headline
    subheading
  }
`;
