import React from "react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import tw from "tailwind.macro";
import LinkExternal from "../Links/LinkExternal";

const Container = styled.article`
  ${tw`w-full relative`}
`;

const Title = styled.h2`
  ${tw`text-3xl font-bold mb-16`}
`;

const Employees = styled.div`
  ${tw`flex flex-wrap`}
`;

const Person = styled.div`
  ${tw`w-full mb-12 sm:mb-0 md:w-1/2 lg:w-1/3 sm:mr-8`}
`;

const Information = styled.div`
  ${tw`mt-8`}
`;

const Name = styled.p`
  ${tw`text-xl`}
`;

function DJBookingTeam({ employees }) {
  return (
    <Container>
      <Title>Teamet bag DJ Booking</Title>
      <Employees>
        {employees.map((employee) => (
          <Person key={employee.name}>
            <img src={employee.picture.imageFile.childImageSharp.gatsbyImageData.src} />
            <Information>
              <Name>{employee.name}</Name>
              <p>{employee.title}</p>
              <LinkExternal
                href={`mailto:${employee.email}`}
                text={employee.email}
              />
            </Information>
          </Person>
        ))}
      </Employees>
    </Container>
  );
}

export default DJBookingTeam;

export const query = graphql`fragment DJBookingTeamFragment on WP_Page_Pagebuilder_Layouts_SectionBlock_Sections_DjBookingTeam {
  employees {
    picture {
      sourceUrl
      altText
      imageFile {
        childImageSharp {
          gatsbyImageData(
            width: 350
            height: 233
            quality: 100
            placeholder: BLURRED
            transformOptions: {cropFocus: CENTER}
            layout: FIXED
          )
        }
      }
    }
    name
    title
    email
  }
}
`;
