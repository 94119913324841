import React from "react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import tw from "tailwind.macro";
import Media from "react-media";
import Checkmark from "./Checkmark";
import businessData from "../../Contact/business";
import ExternalButton from "../../ExternalButton";

const Wrapper = styled.div`
  ${tw`flex flex-col sm:flex-row items-center justify-between`}
`;

const Grid = styled.div`
  ${tw`w-full`}
  ${(props) => (props.rightAligned === true ? tw`sm:text-right` : tw``)}
`;

const Content = styled.article`
  ${tw`text-black-900 w-full`}
  ${(props) => (props.rightAligned === true ? tw`w-full` : tw`sm:w-5/6`)}
`;

const Heading = styled.p`
  ${tw`text-4xl font-bold my-0`}
`;

const CheckmarkWrapper = styled.div`
  ${tw`sm:flex sm:justify-end mt-6 sm:mt-2`}
`;

function CallBureau({ title, description }) {
  const { telephone } = businessData;
  return (
    <Wrapper>
      <Grid>
        <Content>
          <Heading>{title}</Heading>
          <p>{description}</p>
        </Content>
      </Grid>
      <Grid rightAligned={true}>
        <Content rightAligned={true}>
          <Media query={{ minWidth: 640 }}>
            <Heading>
              <a href={`tel:${telephone}`}>Ring til os +45 7021 7025</a>
            </Heading>
          </Media>
          <Media query={{ maxWidth: 640 }}>
            <ExternalButton usp="true" full="true" href={`tel:${telephone}`}>
              Ring direkte til os
            </ExternalButton>
          </Media>
          <CheckmarkWrapper>
            <Checkmark text="Uforpligtende forespørgsel" />
            <Checkmark text="+25 års erfaring i branchen" />
          </CheckmarkWrapper>
        </Content>
      </Grid>
    </Wrapper>
  );
}

export default CallBureau;

export const query = graphql`
  fragment USPCallBureauFragment on WP_Page_Pagebuilder_Layouts_SectionBlock_Sections_UspCallBureau {
    title
    description
  }
`;
