import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import SwipeableViews from 'react-swipeable-views'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

const styles = theme => ({})

class ReviewTabs extends React.Component {
  state = {
    value: 0
  }

  handleChange = (event, value) => {
    this.setState({ value })
  }

  handleChangeIndex = index => {
    this.setState({ value: index })
  }

  render () {
    const { classes, theme } = this.props

    return (
      <React.Fragment>
        <AppBar position="static" color="default">
          <Tabs value={this.state.value} onChange={this.handleChange} indicatorColor="primary" textColor="primary" variant="fullWidth">
            <Tab label={this.props.companyLabel} />
            <Tab label={this.props.privateLabel} />
          </Tabs>
        </AppBar>
        <SwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={this.state.value} onChangeIndex={this.handleChangeIndex}>
          <div dir={theme.direction}>
            {this.props.company}
          </div>
          <div dir={theme.direction}>
            {this.props.private}
          </div>
        </SwipeableViews>
      </React.Fragment>
    )
  }
}

ReviewTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(ReviewTabs)
