import React from "react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import tw from "tailwind.macro";
import Container from "../../Container";

const Wrapper = styled.section`
  ${tw`relative flex items-center w-full h-7-vh overflow-hidden bg-black-900`}
`;

const Overlay = styled.div`
  ${tw`absolute top-0 left-0 w-full bg-black-900 opacity-50 z-10`}
  height: 100%
`;

const Content = styled.article`
  ${tw`flex flex-col justify-center items-center w-full sm:w-3/4 mx-auto z-20`}
`;

const H1 = styled.h1`
  ${tw`text-4xl sm:text-7xl font-semibold text-left sm:text-center mb-0`}
`;

const H4Paragraph = styled.p`
  ${tw`text-2xl w-full sm:w-7/12 text-left sm:text-center mx-auto`}
`;

const BackgroundVideo = styled.video`
  ${tw`absolute min-w-full min-h-full w-auto h-auto z-0`}
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

function Video({ title, subheading, videoUrl }) {
  return (
    <Wrapper>
      <Overlay />
      <Content>
        <Container>
          <H1>{title}</H1>
          <H4Paragraph>{subheading}</H4Paragraph>
        </Container>
      </Content>
      <BackgroundVideo loop autoPlay playsInline muted>
        <source src={videoUrl.link} type="video/mp4" />
        <source src={videoUrl.link} type="video/ogg" />
      </BackgroundVideo>
    </Wrapper>
  );
}

export default Video;

export const categoryVideo = graphql`
  fragment HeroVideoFragment on WP_Page_Pagebuilder_Layouts_SectionBlock_Sections_HeroVideo {
    videoUrl {
      ... on WP_MediaItem {
        link
      }
    }
    title
    subheading
  }
`;
