import React from "react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import tw from "tailwind.macro";
import CategoryDescription from "./CategoryDescription";
import Video from "./Video";

const Wrapper = styled.div`
  ${tw`flex justify-between`}
  ${props => (props.reversed === true ? tw`flex-row-reverse my-16 sm:my-32` : "")}
`;

function SplitCategoryDesc({ categories }) {
  return (
    <React.Fragment>
      {categories.map((category, index) => (
        <Wrapper key={index} reversed={category.reverseLayout}>
          <CategoryDescription
            data={category}
            reversed={category.reverseLayout}
          />
          <Video url={category.video.link} />
        </Wrapper>
      ))}
    </React.Fragment>
  );
}

export default SplitCategoryDesc;

export const query = graphql`
  fragment SplitCategoryDescFragment on WP_Page_Pagebuilder_Layouts_SectionBlock_Sections_SplitCategoryDesc {
    categories {
      reverseLayout
      video {
        ... on WP_MediaItem {
          link
        }
      }
      ...CategoryDescriptionFragment
    }
  }
`;
