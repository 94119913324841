import React from "react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import tw from "tailwind.macro";
import HeroHeadline from "./Hero/Headline";
import HeroVideo from "./Hero/Video";
import SplitCategoryDesc from "./SplitCategoryDesc/index";
import DescBlockLarge from "./DescBlockLarge";
import USPClientLogoSlider from "./USP/ClientLogoSlider";
import USPCallBureau from "./USP/CallBureau";
import USPTestimonialSlider from "./USP/TestimonialSlider";
import DisplayArtistCategory from "./DisplayArtistCategory";
import PricingTable from "./Pricing/Table";
import ColumnBuilderTextBlock from "./ColumnBuilderTextBlock";
import DJBookingTeam from "./DJBookingTeam";
import VideoEmbed from "./VideoEmbed";
import DisplayTestimonials from "./DisplayTestimonials";

function Section({ settings, sections }) {
  const {
    hasVerticalSpacing,
    spacingSize,
    hasContainer,
    hasBackground,
    backgroundColor,
  } = settings;

  return (
    <SectionBlock
      vspace={hasVerticalSpacing}
      vsize={spacingSize}
      bg={hasBackground}
      bgColor={backgroundColor}
    >
      <ContainerBlock className={hasContainer ? "container px-4" : ""}>
        {sections.map((section) => {
          const typeName = section.__typename;

          switch (typeName) {
            case "WP_Page_Pagebuilder_Layouts_SectionBlock_Sections_HeroHeadline":
              return <HeroHeadline key={typeName} {...section} />;

            case "WP_Page_Pagebuilder_Layouts_SectionBlock_Sections_HeroVideo":
              return <HeroVideo key={typeName} {...section} />;

            case "WP_Page_Pagebuilder_Layouts_SectionBlock_Sections_SplitCategoryDesc":
              return <SplitCategoryDesc key={typeName} {...section} />;

            case "WP_Page_Pagebuilder_Layouts_SectionBlock_Sections_DescBlockLarge":
              return <DescBlockLarge key={typeName} {...section} />;

            case "WP_Page_Pagebuilder_Layouts_SectionBlock_Sections_UspClientLogoSlider":
              return <USPClientLogoSlider key={typeName} {...section} />;

            case "WP_Page_Pagebuilder_Layouts_SectionBlock_Sections_UspCallBureau":
              return <USPCallBureau key={typeName} {...section} />;

            case "WP_Page_Pagebuilder_Layouts_SectionBlock_Sections_DisplayArtistCategory":
              return <DisplayArtistCategory key={typeName} {...section} />;

            case "WP_Page_Pagebuilder_Layouts_SectionBlock_Sections_ColumnBuilderTextBlock":
              return <ColumnBuilderTextBlock key={typeName} {...section} />;

            case "WP_Page_Pagebuilder_Layouts_SectionBlock_Sections_DjBookingTeam":
              return <DJBookingTeam key={typeName} {...section} />;

            case "WP_Page_Pagebuilder_Layouts_SectionBlock_Sections_DisplayTestimonials":
              return <DisplayTestimonials key={typeName} {...section} />;

            case "WP_Page_Pagebuilder_Layouts_SectionBlock_Sections_UspTestimonialSlider":
              return <USPTestimonialSlider key={typeName} {...section} />;

            case "WP_Page_Pagebuilder_Layouts_SectionBlock_Sections_PricingTable":
              return <PricingTable key={typeName} {...section} />;

            case "WP_Page_Pagebuilder_Layouts_SectionBlock_Sections_VideoEmbed":
              return <VideoEmbed key={typeName} {...section} />;

            default:
              return <p key={typeName}>{typeName}</p>;
          }
        })}
      </ContainerBlock>
    </SectionBlock>
  );
}

export default Section;

export const fragment = graphql`
  fragment SectionBlockFragment on WP_Page_Pagebuilder_Layouts_SectionBlock {
    settings {
      hasVerticalSpacing
      spacingSize
      hasContainer
      hasBackground
      backgroundColor
    }

    sections {
      __typename

      ...HeroHeadlineFragment
      ...HeroVideoFragment
      ...DescBlockLargeFragment
      ...SplitCategoryDescFragment
      ...USPCallBureauFragment
      ...USPClientLogoSliderFragment
      ...USPTestimonialSliderFragment
      ...VideoEmbedFragment
      ...PricingTableFragment
      ...ColumnBuilderTextBlockFragment
      ...DJBookingTeamFragment
      ...DisplayArtistCategoryFragment
      ...DisplayTestimonialsFragment
    }
  }
`;

const SectionBlock = styled.section`
  ${tw`w-full`}

  ${(props) =>
    props.vspace === null
      ? tw`py-0`
      : props.vsize === "large"
        ? tw`py-32 sm:py-48`
        : props.vsize === "small"
          ? tw`py-8 sm:py-16`
          : tw`py-16 sm:py-32`}
  ${(props) =>
    props.bg === null
      ? tw`bg-black-800`
      : props.bgColor === "feature"
        ? tw`bg-primary`
        : props.bgColor === "light"
          ? tw`bg-black-700`
          : tw`bg-black-800`}
`;

const ContainerBlock = styled.div`
  ${tw`mx-auto`}
`;